import React from "react"
import {
  Hero,
  WhatIs,
  Calendar,
  HolidaySet,
  HolidaySetBanner,
  HolidaySetFlow,
  RelatedVideo,
  SaleChametz,
  DonateBlock,
} from 'components/common'
// import { PapersArchiveIndexBlock } from 'components/papers'
// import { ReviewsIndexBlock } from 'components/reviews'
import { withMainLayout } from 'components/layouts'
import SEO from 'components/seo'

const IndexPage = () => (
  <React.Fragment>
    <SEO
      title='Маца Шмура'
    />
    <Hero />
    <WhatIs />
    <Calendar />
    <HolidaySet />
    <HolidaySetFlow />
    <RelatedVideo />
    <SaleChametz />
    <HolidaySetBanner />
    {/* <ReviewsIndexBlock /> */}
    {/* <PapersArchiveIndexBlock /> */}
    <DonateBlock />
  </React.Fragment>
)

export default withMainLayout(IndexPage)
